import { Text, Image, Stack, Button, Center } from '@chakra-ui/react';
import BillGatesAnimationImage from '../assets/small_gifs/bill-gates.gif';
import GenieAnimationImage from '../assets/small_gifs/genie.gif';
import CountingAnimationImage from '../assets/small_gifs/counter.gif';
import IeLogo from '../assets/small_gifs/ie_logo.gif';
import NoFrames from '../assets/small_gifs/noframes.gif';
import Notepad from '../assets/small_gifs/notepad.gif';
import AnimeBImage from '../assets/small_gifs/anime-b.gif';
import DBZ from '../assets/small_gifs/dbz.gif';

function Footer() {
    return (
        <Stack alignItems="center">
            <Stack
                direction="row"
                spacing={0.5}
                alignItems="flex-end"
                flexWrap="wrap"
                justifyContent="center"
                width={['100vw', 'auto']}
                px={[4, 0]}
                pb={[4, 0]}
            >
                <Image src={BillGatesAnimationImage} maxH="44px" />
                <Image src={GenieAnimationImage} maxH="44px" />
                <Image src={CountingAnimationImage} />
                <Image src={IeLogo} />
                <Image src={NoFrames} />
                <Image src={Notepad} maxH="36px" />
                <Image src={AnimeBImage} />
                <Image src={DBZ} />
            </Stack>
        </Stack>
    );
}

export default Footer;
