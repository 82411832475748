import { Flex, Text, Image, Stack, Center } from '@chakra-ui/react';
import DollarsSpinDown from '../assets/small_gifs/dollarspindownd.gif';
import HanyAnimation from '../assets/small_gifs/ani_hany.gif';
import HanaChan from '../assets/small_gifs/hanachan.gif';
import { Link } from 'react-router-dom';
import CoolLinksImage from '../assets/medium_gifs/coollinks.gif';
import '98.css';

const LINKS = [
    {
        name: 'DExScreener',
        url: 'https://dexscreener.com/solana/9wjafqnxbit1svqblfgdwdehymw4gabadtgwa72yrk39',
    },
    {
        name: 'Telegram',
        url: 'https://t.me/ChrisChanofficial',
    },
    {
        name: 'Twitter',
        url: 'https://twitter.com/ChrisChanSOL',
    },
];

function Heading() {
    return (
        <>
            <Flex display={['none', 'flex']} alignItems="center">
                <Image mx={2} src={HanyAnimation} height="54px" />

                <Text fontSize={['medium', 'x-large']} textShadow="1px 1px 0px #ff0000">
                    <Text as="span" mr={1} fontFamily="Senor Saturno" fontSize={['medium', 'x-large']}>
                        .:
                    </Text>
                    salutations
                    <Text as="span" ml={0.5} fontFamily="Senor Saturno" fontSize={['medium', 'x-large']}>
                        !!
                    </Text>{' '}
                    welc0me t0 the
                </Text>

                <Image ml={1.5} src={DollarsSpinDown} height="30px" />

                <Text fontSize={['medium', 'x-large']} textShadow="1px 1px 0px #ff0000">
                    ChrisChan h0mep4ge
                </Text>

                <Text as="span" ml={1} fontFamily="Senor Saturno" fontSize={['medium', 'x-large']}>
                    :.
                </Text>

                <Image mx={2} src={HanaChan} />
            </Flex>

            <Stack display={['flex', 'none']} alignItems="center">
                <Flex alignItems="center">
                    <Image mx={2} src={HanyAnimation} height="58px" />

                    <Text fontSize={['larger', 'x-large']} textShadow="1px 1px 0px #ff0000">
                        salutations
                        <Text as="span" ml={0.5} fontFamily="Senor Saturno" fontSize={['x-large', 'x-large']}>
                            !!
                        </Text>
                    </Text>

                    <Image mx={3} src={HanaChan} height="60px" />
                </Flex>

                <Text py={2} fontSize={['medium', 'x-large']} textShadow="1px 1px 0px #ff0000">
                    welc0me t0 the ChrisChan h0mep4ge!!
                </Text>
            </Stack>

            <Image src={CoolLinksImage} width={['auto', '520px']} px={4} />

            <Center my={4}>
                <Stack direction="row" spacing={4} justifyContent="center">
                    {LINKS.map((link, index) => (
                        <Link to={link.url} key={index} target="_blank">
                            <button style={{ background: 'silver', minHeight: '28px', minWidth: '88px', fontSize: '13px' }}>
                                {link.name}
                            </button>
                        </Link>
                    ))}
                </Stack>
            </Center>

            <Link to="https://solscan.io/token/AwPgCUmpzvLQV2AXHSKCJuVcGWze9PfFmhjoSujtomwD" target="_blank">
                <Text fontSize={['10px', 'x-large']} layerStyle="scAddr" _hover={{ color: 'cyan' }}>
                    CA: AwPgCUmpzvLQV2AXHSKCJuVcGWze9PfFmhjoSujtomwD
                </Text>
            </Link>
        </>
    );
}

export default Heading;
