import { useEffect } from 'react';
import { theme } from './theme';
import { ChakraBaseProvider } from '@chakra-ui/react';
import { Web3Providers } from './providers';
import { routeNames, routes } from './services/routes';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import '@rainbow-me/rainbowkit/styles.css';

function App() {
    // Init
    useEffect(() => {
        window.localStorage['chakra-ui-color-mode'] = 'dark';
    }, []);

    return (
        <Web3Providers>
            <ChakraBaseProvider theme={theme}>
                <Routes>
                    <Route path={routeNames.root} element={<Layout />}>
                        <Route path="/" element={<Navigate to={routeNames.home} replace />} />

                        {routes.map((route, index) => (
                            <Route path={route.path} key={'route-key-' + index} element={<route.component />} />
                        ))}

                        <Route path="*" element={<Navigate to={routeNames.home} replace />} />
                    </Route>

                    <Route path="*" element={<Navigate to={routeNames.root} replace />} />
                </Routes>
            </ChakraBaseProvider>
        </Web3Providers>
    );
}

export default App;
