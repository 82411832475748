import { Flex, Box, Text, Image, Stack, ListItem, UnorderedList } from '@chakra-ui/react';
import ComingSoon from '../assets/images/coming_soon.png';
import SonicRun from '../assets/blocks/sonic_run.gif';

function Roadmap() {
    const getSpan = (text: string, color: string = 'yellow'): JSX.Element => (
        <Text as="span" layerStyle="senorSaturno" color={color}>
            {text}
        </Text>
    );

    return (
        // <Stack spacing={6} maxW={['auto', '800px']}>
        //     <Stack direction="row" spacing={4}>
        //         <Text layerStyle="fazings" mt={-2} color="nicePurple">
        //             A
        //         </Text>

        //         <Stack spacing={4}>
        //             <Text layerStyle="roadmap">
        //                 {getSpan('Step 0 ', 'nicePurple')}
        //                 normie lotteraffle to keep them fukin sniperz out. {getSpan('1000 tickets : 100 whitelist winners')}.
        //                 one ticket is {getSpan('0.05 eth')}, fuck you very much. have a {getSpan('30 min')} buy window.{' '}
        //                 {getSpan('0.1% of ')}
        //                 {getSpan('%chch', 'orangered')} total supply per account, {getSpan('fcfs')} fuckerz
        //             </Text>

        //             <Stack direction="row">
        //                 <Image src={SonicRun} />
        //                 <Text layerStyle="roadmap">{getSpan('tier 1 culture Callers', 'orangered')}</Text>
        //             </Stack>

        //             <Stack direction="row">
        //                 <Image src={SonicRun} />
        //                 <Text layerStyle="roadmap">{getSpan('tier 2-3 shill Callers', 'orange')}</Text>
        //             </Stack>
        //         </Stack>
        //     </Stack>

        //     <Stack direction="row" spacing={4}>
        //         <Text layerStyle="fazings" mt={-2} color="nicePurple">
        //             B
        //         </Text>

        //         <Stack>
        //             <Text layerStyle="roadmap">{getSpan('Step 1', 'nicePurple')}</Text>
        //             <UnorderedList spacing={3}>
        //                 <ListItem>
        //                     <Text layerStyle="roadmap">tranny motherfucker bot (yes, ai motherfuckers)</Text>
        //                 </ListItem>
        //                 <ListItem>
        //                     <Text layerStyle="roadmap" color="yellow">
        //                         continue tier {getSpan('1', 'orange')} culture callers
        //                     </Text>
        //                 </ListItem>
        //                 <ListItem>
        //                     <Text layerStyle="roadmap">bribe dextools trending</Text>
        //                 </ListItem>
        //                 <ListItem>
        //                     <Text layerStyle="roadmap" color="yellow">
        //                         bribe coingecko
        //                     </Text>
        //                 </ListItem>
        //                 <ListItem>
        //                     <Text layerStyle="roadmap">{getSpan('4', 'orange')}chan campaign</Text>
        //                 </ListItem>
        //                 <ListItem>
        //                     <Text layerStyle="roadmap" color="yellow">
        //                         tranny ads
        //                     </Text>
        //                 </ListItem>
        //                 <ListItem>
        //                     <Text layerStyle="roadmap">start tier {(getSpan('1'), 'orange')} kols</Text>
        //                 </ListItem>
        //                 <ListItem>
        //                     <Text layerStyle="roadmap" color="yellow">
        //                         start x spaces hosts
        //                     </Text>
        //                 </ListItem>
        //             </UnorderedList>
        //         </Stack>
        //     </Stack>

        //     <Stack direction="row" spacing={4}>
        //         <Text layerStyle="fazings" mt={-2} color="nicePurple">
        //             C
        //         </Text>

        //         <Stack>
        //             <Text layerStyle="roadmap">{getSpan('Step 2', 'nicePurple')}</Text>
        //             <UnorderedList spacing={3}>
        //                 <ListItem>
        //                     <Text layerStyle="roadmap">
        //                         pumpichu cumshot game embedded on the website - play for free, swallow cumshots
        //                     </Text>
        //                 </ListItem>
        //                 <ListItem>
        //                     <Text layerStyle="roadmap" color="yellow">
        //                         tier {getSpan('1', 'orange')} kol shameless shill campaign
        //                     </Text>
        //                 </ListItem>
        //                 <ListItem>
        //                     <Text layerStyle="roadmap">bribe tier {getSpan('0', 'orange')} culture callers</Text>
        //                 </ListItem>
        //                 <ListItem>
        //                     <Text layerStyle="roadmap" color="yellow">
        //                         regular bullyshit spaces on x
        //                     </Text>
        //                 </ListItem>
        //             </UnorderedList>
        //         </Stack>
        //     </Stack>

        //     <Stack direction="row" spacing={4}>
        //         <Text layerStyle="fazings" mt={-2} color="nicePurple">
        //             D
        //         </Text>

        //         <Stack>
        //             <Text layerStyle="roadmap">{getSpan('Step 3', 'nicePurple')}</Text>
        //             <UnorderedList spacing={3}>
        //                 <ListItem>
        //                     <Text layerStyle="roadmap">
        //                         launch web-based staking dApp, get NFTs for staking, pumpichu cumshot game
        //                     </Text>
        //                 </ListItem>
        //                 <ListItem>
        //                     <Text layerStyle="roadmap" color="yellow">
        //                         leaderboard gets you higher APY
        //                     </Text>
        //                 </ListItem>
        //                 <ListItem>
        //                     <Text layerStyle="roadmap">
        //                         continue tier {getSpan('0', 'orange')} {getSpan('+', 'orange')} tier {getSpan('1', 'orange')}{' '}
        //                         KOL shameless shills
        //                     </Text>
        //                 </ListItem>
        //                 <ListItem>
        //                     <Text layerStyle="roadmap" color="yellow">
        //                         start tik tok normie shills campaign with blue chip tik tok shitfluencers
        //                     </Text>
        //                 </ListItem>
        //             </UnorderedList>
        //         </Stack>
        //     </Stack>

        //     <Stack direction="row" spacing={4}>
        //         <Text layerStyle="fazings" mt={-2} color="nicePurple">
        //             n
        //         </Text>

        //         <Stack>
        //             <Text layerStyle="roadmap">{getSpan('Step 4', 'nicePurple')}</Text>
        //             <UnorderedList spacing={3}>
        //                 <ListItem>
        //                     <Text layerStyle="roadmap" color="orangered">
        //                         fuck stepsister
        //                     </Text>
        //                 </ListItem>
        //             </UnorderedList>
        //         </Stack>
        //     </Stack>
        // </Stack>
        <Stack alignItems="center">
        <Image src={ComingSoon} w={['80%', '70%']} maxW="600px" />
    </Stack>

    );
}

export default Roadmap;
