import { Flex, Box, Text, Image, Stack } from '@chakra-ui/react';
import Heading from '../components/Heading';
import Marquee from 'react-fast-marquee';

// Medium GIFs
import CellPikachu from '../assets/medium_gifs/cell-pikachu.gif';
import ConPikachu from '../assets/medium_gifs/conpikachu.gif';
import Pikaani from '../assets/medium_gifs/pikaani.gif';

// Images
import Leo from '../assets/images/leo.jpg';

function Home() {
    return (
        <Stack alignItems="center" height="100%" justifyContent="space-around">
            <Stack alignItems="center">
                <Heading />
                <Image src={Pikaani} width={['auto', '320px']} />
            </Stack>

            <Image src={Leo} width={['auto', '540px']} alt="Leo" />

            <Box width={['auto', '420px']}>
                <Marquee speed={220} style={{ backgroundColor: 'yellow', color: 'dodgerblue', fontSize: '18px' }}>
                    Go Pumpichu, go and moon!!
                </Marquee>
            </Box>
        </Stack>
    );
}

export default Home;
