import { ThemeConfig, extendTheme } from '@chakra-ui/react';
import { getPatternPNG } from './services/assets';

const config: ThemeConfig = {
    initialColorMode: 'dark',
    useSystemColorMode: false,
};

const colors = {
    primaryFontColor: {
        lightMode: 'whitesmoke',
        darkMode: 'whitesmoke',
    },
    mintGreen: '#16ff73',
    nicePurple: '#ff1ee7',
    redClrs: '#FF4136',
};

const fonts = {
    body: "'Naiveer', sans-serif",
    heading: "'Naiveer', sans-serif",
};

const breakpoints = {
    base: '0em',
    sm: '30em',
    md: '48em',
    lg: '74em',
    xl: '92em',
    '2xl': '104em',
};

const layerStyles = {
    comicSans: {
        fontFamily: '"Comic Sans MS", "Comic Sans", cursive',
    },
    senorSaturno: {
        fontFamily: 'Senor Saturno',
        fontWeight: 700,
    },
    roadmap: {
        color: 'mintGreen',
        fontSize: '22px',
    },
    fazings: {
        fontFamily: 'Fazings',
        fontSize: '34px',
        fontWeight: 900,
    },
    headerText: {
        fontFamily: 'Senor Saturno',
        fontWeight: 900,
        letterSpacing: '1px',
        textShadow: '1px 1px 1px black',
        cursor: 'pointer',
    },
    scAddr: {
        fontFamily: 'Senor Saturno',
        color: 'nicePurple',
        textShadow: '0 0 1px black',
    },
};

const styles = {
    global: () => ({
        body: {
            backgroundImage: `url(${getPatternPNG()})`,
            bg: 'transparent',
            overflowY: ['scroll', 'hidden'],
        },
    }),
};

export const theme = extendTheme({
    config,
    colors,
    fonts,
    breakpoints,
    layerStyles,
    styles,
});
