import { Image, Stack, Text } from '@chakra-ui/react';
import ComingSoon from '../assets/images/coming_soon.png';
import Senpai from '../assets/images/senpai.png';
import { useEffect } from 'react';

function Buy() {
    useEffect(() => {
        window.Jupiter.init({
            displayMode: 'integrated',
            integratedTargetId: 'integrated-terminal',
            endpoint: 'https://dry-lively-season.solana-mainnet.quiknode.pro/c66f7e0c58bc0176cdb891aeeb97304aeddf6d81/', // 'https://api.mainnet-beta.solana.com',
            formProps: {
                fixedOutputMint: true,
                initialOutputMint: 'AwPgCUmpzvLQV2AXHSKCJuVcGWze9PfFmhjoSujtomwD',
            },
        });
    }, []);

    return (
        <Stack alignItems="center">
            <Text layerStyle="comicSans" color="pink" textShadow="1px 1px red" fontSize="x-large">
                p-please senpai /// can you buy some ChrisChan t-tokens?
            </Text>
            <Image src={Senpai} h="360px" />

            <div id="integrated-terminal"></div>
        </Stack>
    );
}

export default Buy;
