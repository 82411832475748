import './global.scss';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-T9DETHK19V');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <BrowserRouter>
        <ChakraProvider>
            <ColorModeScript initialColorMode="light" />
            <App />
        </ChakraProvider>
    </BrowserRouter>
);
