import { Flex, Box, Text, Image, Stack, Center } from '@chakra-ui/react';
import { NavLink, Outlet } from 'react-router-dom';
import { getUglyPattern, getCat, getPatternPNG, getPatternGIF, getLogo } from '../services/assets';
import { routes } from '../services/routes';
import Footer from './Footer';

// Small GIFs
import Animoon from '../assets/small_gifs/a_and_r_animoon.gif';
import OSRS from '../assets/small_gifs/arrowspin1.gif';
import SonicCoin from '../assets/small_gifs/soniccoin.gif';
import MoonBlue from '../assets/small_gifs/moon_blue.gif';
import { Connect } from './Connect';
import Navigation from './Navigation';

const PADDING = 6;

function Layout() {
    return (
        <Stack direction={['column', 'row']} spacing={0}>
            <Navigation />

            <Stack height="100%" minH="100vh" width="100%" spacing={0}>
                <Flex
                    alignItems="center"
                    justifyContent="flex-end"
                    backgroundImage={`url(${getUglyPattern()})`}
                    py={[1, 0]}
                    minH="36px"
                >
                    <Connect />
                </Flex>

                <Flex
                    height="100%"
                    position="relative"
                    justifyContent="center"
                    backgroundColor="gray"
                    backgroundImage={`url(${getPatternPNG()})`}
                    backgroundRepeat="repeat"
                >
                    {/* Glitter */}
                    <Box
                        position="absolute"
                        top={0}
                        right={0}
                        bottom={0}
                        left={0}
                        zIndex={1}
                        pointerEvents="none"
                        backgroundImage={`url(${getPatternGIF()})`}
                    ></Box>

                    {/* Corners */}
                    <Flex
                        display={['none', 'flex']}
                        flexDir="column"
                        justifyContent="space-between"
                        position="absolute"
                        padding={PADDING}
                        top={0}
                        right={0}
                        bottom={0}
                        left={0}
                        zIndex={2}
                    >
                        <Flex justifyContent="space-between" alignItems="flex-start" width="100%">
                            <Image src={Animoon} width="88px" />
                            <Image src={OSRS} />
                        </Flex>

                        <Flex justifyContent="space-between" alignItems="flex-end" width="100%">
                            <Image src={SonicCoin} width="86px" />
                            <Image src={MoonBlue} width="82px" />
                        </Flex>
                    </Flex>

                    <Stack
                        height={`calc(100vh - 36px)`}
                        width="100%"
                        p={PADDING}
                        spacing={8}
                        alignItems="center"
                        justifyContent="space-between"
                        zIndex={3}
                        overflowY={['initial', 'auto']}
                    >
                        <Image src={getLogo()} width={['auto', '380px']} margin="0 auto" alt="Logo" px={6} />
                        <Outlet />
                        <Footer />
                    </Stack>
                </Flex>
            </Stack>
        </Stack>
    );
}

export default Layout;
