import { Flex, Stack, Text, Image, Box } from '@chakra-ui/react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { FunctionComponent, PropsWithChildren } from 'react';

export const Connect = () => {
    return (
        // <ConnectButton.Custom>
        //     {({ account, chain, openAccountModal, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
        //         const ready = mounted && authenticationStatus !== 'loading';
        //         const connected =
        //             ready && account && chain && (!authenticationStatus || authenticationStatus === 'authenticated');

        //         return (
        //             <div
        //                 {...(!ready && {
        //                     'aria-hidden': true,
        //                     style: {
        //                         opacity: 0,
        //                         pointerEvents: 'none',
        //                         userSelect: 'none',
        //                     },
        //                 })}
        //             >
        //                 <Flex justifyContent="flex-end" pr={4}>
        //                     {!connected ? (
        //                         <TextButton onClick={openConnectModal}>Connect Wallet</TextButton>
        //                     ) : !!chain.unsupported ? (
        //                         <TextButton onClick={openChainModal}>Wrong Network</TextButton>
        //                     ) : (
        //                         <Stack direction="row" spacing={4} alignItems="center">
        //                             <Box onClick={openChainModal}>
        //                                 <Stack direction="row" spacing={2} alignItems="center">
        //                                     {chain.hasIcon && chain.iconUrl && <Image src={chain.iconUrl} py={1} />}
        //                                     <Text layerStyle="headerText" color="lavender" _hover={{ color: 'magenta' }}>
        //                                         {chain.name}
        //                                     </Text>
        //                                 </Stack>
        //                             </Box>

        //                             <TextButton onClick={openAccountModal}>
        //                                 {account.displayName}
        //                                 {account.displayBalance ? ` (${account.displayBalance})` : ''}
        //                             </TextButton>
        //                         </Stack>
        //                     )}
        //                 </Flex>
        //             </div>
        //         );
        //     }}
        // </ConnectButton.Custom>
        <></>
    );
};

const TextButton: FunctionComponent<PropsWithChildren<{ onClick: () => void }>> = ({ children, onClick }) => {
    return (
        <Text layerStyle="headerText" fontSize={['16px', '20px']} onClick={onClick} color="cyan" _hover={{ color: 'pink' }}>
            {children}
        </Text>
    );
};
