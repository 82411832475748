'use client';

import * as React from 'react';

// import { RainbowKitProvider, Theme, connectorsForWallets } from '@rainbow-me/rainbowkit';
// import {
//     injectedWallet,
//     phantomWallet,
//     metaMaskWallet,
//     coinbaseWallet,
//     walletConnectWallet,
//     trustWallet,
//     rainbowWallet,
// } from '@rainbow-me/rainbowkit/wallets';
// import { configureChains, createConfig, WagmiConfig } from 'wagmi';
// import { mainnet, sepolia, hardhat } from 'wagmi/chains';
// import { publicProvider } from 'wagmi/providers/public';
// import { infuraProvider } from 'wagmi/providers/infura';


// const projectId = '2b756061f810e52a725969a68c95e1e7';

// const { chains, publicClient, webSocketPublicClient } = configureChains(
//     [mainnet, ...(process.env.NODE_ENV === 'development' ? [mainnet, sepolia, hardhat] : [])],
//     [
//       infuraProvider({ apiKey: "100f9b8334614e3289becb4ab834bd48" }),
//       publicProvider(),
//     ]
//   );

// const connectors = connectorsForWallets([
//     {
//         groupName: 'Recommended',
//         wallets: [phantomWallet({chains}), metaMaskWallet({ projectId, chains }), rainbowWallet({projectId, chains})],
//     },
//     {
//         groupName: 'Others',
//         wallets: [
//             coinbaseWallet({ chains, appName: 'My RainbowKit App' }),
//             walletConnectWallet({ projectId, chains }),
//             trustWallet({ projectId, chains }),
//         ],
//     },
// ]);

// const config = createConfig({
//     autoConnect: true,
//     connectors,
//     publicClient,
// });

// //wallet connector theme can be adjusted, more found here. https://www.rainbowkit.com/docs/theming https://www.rainbowkit.com/docs/custom-theme
// const myCustomTheme: Theme = {
//     blurs: {
//         modalOverlay: '...',
//     },
//     colors: {
//         accentColor: '...',
//         accentColorForeground: '...',
//         actionButtonBorder: '...',
//         actionButtonBorderMobile: '...',
//         actionButtonSecondaryBackground: '...',
//         closeButton: '...',
//         closeButtonBackground: '...',
//         connectButtonBackground: '...',
//         connectButtonBackgroundError: '...',
//         connectButtonInnerBackground: '...',
//         connectButtonText: '...',
//         connectButtonTextError: '...',
//         connectionIndicator: '...',
//         downloadBottomCardBackground: '...',
//         downloadTopCardBackground: '...',
//         error: '...',
//         generalBorder: '...',
//         generalBorderDim: '...',
//         menuItemBackground: '...',
//         modalBackdrop: '...',
//         modalBackground: '#07296d',
//         modalBorder: '...',
//         modalText: '...',
//         modalTextDim: '...',
//         modalTextSecondary: '...',
//         profileAction: '...',
//         profileActionHover: '...',
//         profileForeground: '...',
//         selectedOptionBorder: '...',
//         standby: '...',
//     },
//     fonts: {
//         body: '...',
//     },
//     radii: {
//         actionButton: '...',
//         connectButton: '...',
//         menuButton: '...',
//         modal: '...',
//         modalMobile: '...',
//     },
//     shadows: {
//         connectButton: '...',
//         dialog: '...',
//         profileDetailsAction: '...',
//         selectedOption: '...',
//         selectedWallet: '...',
//         walletLogo: '...',
//     },
// };

export function Web3Providers({ children }: { children: React.ReactNode }) {
    const [mounted, setMounted] = React.useState(false);
    React.useEffect(() => setMounted(true), []);
    return (
        // <WagmiConfig config={config}>
        //     <RainbowKitProvider chains={chains} theme={myCustomTheme} showRecentTransactions={true} initialChain={mainnet}>
        //         c
        //     </RainbowKitProvider>
        // </WagmiConfig>
        <div> {mounted && children}</div>
    );
}
