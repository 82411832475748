import Home from '../pages/Home';
import Roadmap from '../pages/Roadmap';
import Staking from '../pages/Staking';
import Whitelist from '../pages/Whitelist';
import Buy from '../pages/Buy';
import Presale from '../pages/Presale';

export const routeNames = {
    root: '/',
    home: 'home',
    // whitelist: 'whitelist',
    // staking: 'staking',
    roadmap: 'roadmap',
    buy: 'buy',
    // presale: 'presale'
};

export const routes = [
    {
        path: routeNames.home,
        component: Home,
    },
    // {
    //     path: routeNames.whitelist,
    //     component: Whitelist,
    // },
    {
        path: routeNames.roadmap,
        component: Roadmap,
    },
    // {
    //     path: routeNames.staking,
    //     component: Staking,
    // },
    {
        path: routeNames.buy,
        component: Buy,
    },
    // {
    //     path: routeNames.presale,
    //     component: Presale,
    // },
];
