import { Text, Image, Box, Center, Stack } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { getUglyPattern, getCat } from '../services/assets';
import { routes } from '../services/routes';
import { FunctionComponent, PropsWithChildren } from 'react';

function Navigation() {
    return (
        <Box backgroundImage={`url(${getUglyPattern()})`} backgroundSize="cover">
            <Stack display={['none', 'flex']} width="240px" spacing={12} px={6} py={10}>
                {routes.map((route, index) => (
                    <Box key={index} position="relative" color="black" cursor="pointer" _hover={{ color: 'cyan' }}>
                        <Cat route={route.path} />
                    </Box>
                ))}
            </Stack>

            <Box
                display={['grid', 'none']}
                py={4}
                px={12}
                gridAutoColumns="1fr 1fr"
                gridTemplateColumns="1fr 1fr"
                rowGap={1}
                columnGap="10%"
            >
                {routes.map((route, index) => (
                    <Box key={index} position="relative" color="black" _hover={{ color: 'cyan' }}>
                        <Cat route={route.path} />
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

export default Navigation;

const Cat: FunctionComponent<PropsWithChildren<{ route: string }>> = ({ route }) => {
    return (
        <NavLink to={route}>
            <Image src={getCat()} filter="sepia(0.5) drop-shadow(2px 4px 6px black)" margin="0 auto" />

            <Center position="absolute" top={0} right={0} bottom={0} left={0} zIndex={1}>
                <Text
                    pl={6}
                    pt={3}
                    layerStyle="comicSans"
                    fontSize={['medium', 'larger']}
                    fontWeight={600}
                    textTransform="uppercase"
                    letterSpacing="0.75px"
                >
                    {route}
                </Text>
            </Center>
        </NavLink>
    );
};
