import Logo from '../assets/logo.png';
import PatternPNG from '../assets/blocks/pattern_small.png';
import PatternGIF from '../assets/blocks/pattern.gif';

import Cat from '../assets/blocks/cat_deepfried.png';
import UglyPattern from '../assets/blocks/ugly_pattern.png';
import Spinner from '../assets/blocks/loading.gif';
import ETH from '../assets/blocks/eth.png';

export const getLogo = () => Logo;
export const getPatternPNG = () => PatternPNG;
export const getPatternGIF = () => PatternGIF;

export const getCat = () => Cat;
export const getUglyPattern = () => UglyPattern;

export const getSpinner = () => Spinner;
export const getETH = () => ETH;
